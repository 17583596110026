new WOW().init();

const MENU_BTN = document.querySelector('.header__btn')
const MENU = document.querySelector('.header__menu')
const FILTER_BTN = document.querySelectorAll('.filter-btn')
const SERVICES_ITEM = document.querySelectorAll('.services-item')
const SERVICES_BTN = document.querySelector('.services-btn')

const WIDGET = document.querySelector('.widget')
const WIDGET_BTN = document.querySelector('.widget__btn')
const SCROLL_BTN = document.querySelector('.top-scroll')

const MODAL_BG = document.querySelector('.modal-bg')
const MODAL_THANKS = document.querySelector('.modal-thanks')
const closeModalBtn = document.querySelectorAll('.closeModal')
const openModal = document.querySelectorAll('.openModal')
const modalItems = document.querySelectorAll('.modal')

//Открытие мобильного меню
MENU_BTN.addEventListener('click', () => {
    MENU.classList.toggle('active')
    MENU_BTN.classList.toggle('active')
})

//Закрытие меню при изменении ширины экрана
$(window).resize(function () {
    if ($(this).width() > 1199) {
        MENU.classList.remove('active')
        MENU_BTN.classList.remove('active')
    }
})

//Переключение услуг в фильтре
function setActiveTab(activeTab) {
    SERVICES_ITEM.forEach((item) => {
        if (item.getAttribute('data-tab') !== activeTab) {
            item.classList.remove('active')
        } else {
            item.classList.add('active')
        }

    })
}

// Переключение услуг
FILTER_BTN.forEach((item) => {
    item.addEventListener('click', () => {
        FILTER_BTN.forEach((item) => {
            item.classList.remove('active')
        })
        item.classList.add('active')
        setActiveTab(item.getAttribute('data-tab'))
    })
})

setActiveTab('1')

window.addEventListener('load', function(){
    let items = document.querySelectorAll('.services__filter');
    for (let i = 0; i < items.length; i++){
        items[i].addEventListener('click', listOpen);
    }
    function listOpen(){
        if(this.classList.contains('active')){
            this.classList.remove('active');
            SERVICES_BTN.classList.remove('active')
        }
        else{
            this.classList.add('active');
            SERVICES_BTN.classList.add('active')
        }
    }
})

const TAB_ONE = document.querySelectorAll('.text-block-1')
const TAB_TWO = document.querySelectorAll('.text-block-2')

//Раскрытие табов в блоке Реализованные проекты
TAB_ONE.forEach((item) => {
    item.addEventListener('click', () => {
        if (item.classList.contains('active')) {
            item.classList.remove('active')
        } else {
            item.classList.add('active')
        }
    })
})

TAB_TWO.forEach((item) => {
    item.addEventListener('click', () => {
        if (item.classList.contains('active')) {
            item.classList.remove('active')
        } else {
            item.classList.add('active')
        }
    })
})

const SLIDE_BTN = document.querySelectorAll('.slide-btn')

SLIDE_BTN.forEach((item) => {
    item.addEventListener('click' , () => {
        TAB_TWO.forEach((item) => {
            item.classList.remove('active')
        })
    })
})

const FOOTER = document.querySelector('.footer')
let pageHeight = document.documentElement.scrollHeight
let footerHeight = FOOTER.clientHeight
let windowInnerHeight = window.innerHeight
let width = window.innerWidth

window.addEventListener('resize', () => {
    pageHeight = document.documentElement.scrollHeight
    footerHeight = FOOTER.offsetHeight
    windowInnerHeight = window.innerHeight
    width = window.innerWidth
})

let prev = 0

// Функция появления мобильного виджета
WIDGET_BTN.addEventListener('click', () => {
    WIDGET.classList.toggle('active')
    WIDGET_BTN.classList.toggle('active')
})

// Установка возраста компании
const setYear = document.querySelector('.setYear')
const companyBirthDate = new Date(2005, 8, 16)
const today = new Date()
const companyOld = today.getFullYear() - companyBirthDate.getFullYear()
setYear.setAttribute('data-end', companyOld.toString())

// Бегущие цифры, запуск внизу где отслеживание скрола.
const counterNum = document.querySelectorAll('.counter-num')
const counter = document.querySelector('.counter')
const counterWrapper = document.querySelector('.counter__wrapper')
let isStartedCount = false

function counterPlus(count) {
    const endCount = +count.getAttribute('data-end')
    const delay = +count.getAttribute('data-time') / endCount
    const step = +count.getAttribute('data-step')
    let currentCount = +count.getAttribute('data-start')
    let endSymbol = ''
    if (count.getAttribute('data-symbol')) {
        endSymbol = count.getAttribute('data-symbol')
    }

    count.innerText = currentCount
    const timer = setInterval(() => {
        if (+endCount <= +currentCount) {
            clearInterval(timer)
            count.innerText = currentCount + endSymbol
        } else {
            currentCount = currentCount + step
            count.innerText = currentCount
        }
    }, delay)
}

window.addEventListener('scroll', () => {

    let scrollPosition = pageHeight - footerHeight - 600
    let position = scrollY + windowInnerHeight

    if (scrollPosition <= position) {
        if (width <= 767) {
            SCROLL_BTN.style.bottom = '30px'
        }
        if (width < 1366) {
            SCROLL_BTN.style.bottom = '80px'
        }

    } else {
        SCROLL_BTN.style.bottom = '30px'
    }

    if (prev > scrollY && scrollY > 300) {
        SCROLL_BTN.classList.add('active')
        WIDGET.classList.remove('show')

    } else {
        SCROLL_BTN.classList.remove('active')
        WIDGET.classList.add('show')
    }

    prev = scrollY
    // Запуск счётчика
    if (scrollY >= counter.offsetTop - document.documentElement.clientHeight + 5500 && !isStartedCount) {
        counterNum.forEach((item) => {
            counterPlus(item)
        })
        counterWrapper.classList.add('show-counter')
        isStartedCount = true
    }
})

//Инициализация слайдеров
function sliderInit() {
    const clients = new Swiper('.clients__slider', {
        loop: true,
        autoplay: {
            delay: 2000,
        },

        pagination: {
            el: '.clients-pagination',
            clickable: true,
        },

        breakpoints: {
            320: {
                slidesPerView: 2,
                centeredSlides: false,
                grid: {
                    rows: 2,
                },
                spaceBetween: 15,
            },
            767: {
                slidesPerView: 4,
                centeredSlides: false,
                grid: false,
                spaceBetween: 25,
                loop: true,
                autoplay: {
                    delay: 2000,
                },
            },

            1199: {
                slidesPerView: 5,
                centeredSlides: true,
            },
        },
    });

    const projects = new Swiper('.projects__slider', {
        slidesPerView: 1,
        loop: false,
        speed: 500,
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },

        pagination: {
            el: '.projects-pagination',
            type: "fraction",
        },

        navigation: {
            nextEl: '.projects-button-next',
            prevEl: '.projects-button-prev',
        },
    });
}

sliderInit()

//Остановка прокрутки сайта
function scrollStop() {
    document.querySelector("html").style.overflow = 'hidden'
}

//Восстановление прокрутки сайта
function scrollAuto() {
    document.querySelector("html").style.overflow = ''
}

//Функция закрытия модальных окон
function closeModal() {
    MODAL_BG.classList.remove('active')
    modalItems.forEach((item) => {
        item.classList.remove('active')
    })
    scrollAuto()
}
//Модальное окно СПАСИБО
function openThanksModal() {
    closeModal()
    MODAL_BG.classList.add('active')
    MODAL_THANKS.classList.add('active')

}
//Закрытие модального окна по клику на крестик
closeModalBtn.forEach((item) => {
    item.addEventListener('click', () => {
        closeModal()
    })
})
//Открытие модального окна
openModal.forEach((item) => {
    item.addEventListener('click', () => {
        modalItems.forEach((modals) => {
            if (modals.classList.contains(item.getAttribute('data-modal'))) {
                MODAL_BG.classList.add('active')
                modals.classList.add('active')
                scrollStop()
            }
        })
    })
})

// Маска ввода телефона
$(document).ready(function () {
    $(".phone-mask").mask("9 (999) 999-99-99");
});

//Прикрепить файл
$(document).ready(function () {
    $("#upload").change(function () {
        let filename = $(this).val().replace(/.*\\/, "");
        $("#file-name").html(filename);
    });
});

$(document).ready(function () {
    $("#uploadModal").change(function () {
        let filename = $(this).val().replace(/.*\\/, "");
        $("#file-name-modal").html(filename);
    });
});

$(function () {
    $('form').submit(function (e) {
        e.preventDefault();
        let $form = $(this),
            formID;
        let formData = new FormData(this);
        $("form#analytics").serializeArray().forEach(function (field) {
            formData.append(field.name, field.value)
        });
        formID = "#" + $form.attr('id') + " button";  // Формируем переменную с номер формы и добавляем селектор button
        $(formID).prop('disabled', true);
        $(formID).css({'opacity': 0.3});

        if ($form.hasClass('catalogSend')) {
            $.ajax({
                url: './php/send-catalog.php',
                type: 'POST',
                contentType: false,
                processData: false,
                data: new FormData(this)
            })
            $.ajax({
                url: $form.attr('action'),
                type: $form.attr('method'),
                data: formData,
                cache: false,
                contentType: false,
                processData: false
            }).done(function () {
                $('form').trigger('reset');
                $(formID).prop('disabled', false);
                $(formID).css({'opacity': 1});
                openThanksModal()
            });

        } else {
            console.log('отправка Лида');
            $.ajax({
                url: $form.attr('action'),
                type: $form.attr('method'),
                data: formData,
                cache: false,
                contentType: false,
                processData: false
            }).done(function () {
                $('form').trigger('reset');
                $(formID).prop('disabled', false);
                $(formID).css({'opacity': 1});
                openThanksModal()
            });
        }

    });
});

$(document).ready(function () {
    let url_string = location.href;
    let url = new URL(url_string);
    let utm_source = url.searchParams.get('utm_source');
    let utm_medium = url.searchParams.get('utm_medium');
    let utm_campaign = url.searchParams.get('utm_campaign');
    let utm_content = url.searchParams.get('utm_content');
    let utm_term = url.searchParams.get('utm_term');
    let yclid = url.searchParams.get('yclid');
    let gclid = url.searchParams.get('gclid');
    let pm_position = url.searchParams.get('pm_position');
    let keyword = url.searchParams.get('keyword');
    let clientid;
    let googlecid;

    $('.analytics input[name="utm_source"]').val(utm_source);
    $('.analytics input[name="utm_medium"]').val(utm_medium);
    $('.analytics input[name="utm_campaign"]').val(utm_campaign);
    $('.analytics input[name="utm_content"]').val(utm_content);
    $('.analytics input[name="utm_term"]').val(utm_term);
    $('.analytics input[name="yclid"]').val(yclid);
    $('.analytics input[name="gclid"]').val(gclid);
    $('.analytics input[name="pm_position"]').val(pm_position);
    $('.analytics input[name="keyword"]').val(keyword);

    if (document.cookie.search('(?:^|;)\\s*_ga=([^;]*)') !== -1) {
        googlecid = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)');
        $('.analytics input[name="googlecid"]').val(googlecid[0].slice(10));
    } else {
        googlecid = 'GA - отсуствует'
        $('.analytics input[name="googlecid"]').val(googlecid);
    }

    if (document.cookie.search('(?:^|;)\\s*_ym_uid=([^;]*)') !== -1) {
        clientid = document.cookie.match('(?:^|;)\\s*_ym_uid=([^;]*)');
        $('.analytics input[name="clientid"]').val(clientid[1]);
    } else {
        clientid = 'YM - отсуствует'
        $('.analytics input[name="clientid"]').val(clientid);
    }
});
